export const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 704,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    secondary: { main: '#C73D5D' },
    error: { main: '#DC4E34' },
    success: { main: '#6E953B' },
    warning: { main: '#EEA320' },
    primary: { main: '#3C77AE' },
    common: {
      black: '#2C261E'
    },
    background: {
      default: '#f9f9f9',
      gradient:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)'
    },
    duotone: {
      '--fa-primary-color': '#3C77AE',
      '--fa-secondary-color': '#EEA320'
    },
    text: {
      primary: '#1d1d1f',
      secondary: '#86868b'
      // primary: '#666'
    }
  },
  typography: {
    body1: {
      fontSize: '1.125em',
      lineHeight: 1.5
    },
    body2: {
      fontSize: '1em',
      lineHeight: 1.5
    },
    h1: {
      fontSize: '4.5em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h2: {
      fontSize: '3.75em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h3: {
      fontSize: '3em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h4: {
      fontSize: '2.25em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h5: {
      fontSize: '1.875em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    h6: {
      fontSize: '1.5em',
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`,
      lineHeight: 1.1
    },
    caption: {
      fontSize: '0.75em'
    },
    fontFamily: `"Sarabun", "Roboto", "Helvetica", "Arial", sans-serif`,
    htmlFontSize: 16,
    fontSize: 16
  },
  shape: {
    toolbarHeight: 60,
    sidebarWidth: 270,
    fluid: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'sm'
      }
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true
      },
      styleOverrides: {
        root: {
          backgroundColor: 'red'
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'xs',
        disableEnforceFocus: true
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          minWidth: 48,
          minHeight: 48
        }
      }
    },
    MuiList: {
      defaultProps: {
        disablePadding: true
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
          paddingRight: 8
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        secondaryTypographyProps: {
          variant: 'caption'
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        elevation: 1,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        elevation0: {
          border: 'solid 1px #e0e0e0'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          '&:not(:last-child)': {
            marginBottom: 16
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 60,
          maxHeight: 60,
          minHeight: '60px !important'
        }
      }
    }
  }
}
