import {
  AppBar,
  IconButton,
  Link as MLink,
  Toolbar,
  Typography
} from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { useMC } from '../ctx'
import { BarIcon } from '../../Icons'
import { MCProfile } from '../profile'
import { Link } from 'react-router-dom'

const EnhanceAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme?.palette?.text?.primary
}))

const EnhanceLink = styled((props) => <MLink component={Link} {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme?.palette?.text?.primary,
  })
)

export const MCAppBar = (props) => {
  const { sidebar, isMobile, open, handleOpen, logo, sitename, appBarStart } = useMC()

  return (
    <EnhanceAppBar elevation={0}>
      <Toolbar>
        {isMobile && sidebar && (
          <IconButton
            edge='start'
            onClick={handleOpen('sidebar', !open.sidebar)}
          >
            <BarIcon open={open?.sidebar} />
          </IconButton>
        )}
        <EnhanceLink to='/' style={{marginRight:16}}>
          <img src={logo} style={{ maxHeight: 48 }} />
          {sitename && !isMobile && (
            <Typography variant='h6' style={{ marginLeft: 8 }}>
              {sitename}
            </Typography>
          )}
        </EnhanceLink>
        {appBarStart}
        <Box sx={{ flex: 1 }} />
        <MCProfile />
      </Toolbar>
    </EnhanceAppBar>
  )
}
