import { Avatar, CircularProgress, IconButton, Menu } from '@mui/material'
import React, { useRef } from 'react'
import { useMC } from '../ctx'
import { MCProfileNotSigned } from './not.sign'
import { MCProfileSigned } from './signed'

export const MCProfile = (props) => {
  const anchorEl = useRef()
  const { user, open, handleOpen } = useMC()

  return (
    <React.Fragment>
      <IconButton
        ref={anchorEl}
        edge='end'
        onClick={handleOpen('profile', !open?.profile)}
      >
        {user?.fetched ? (
          <Avatar src={user?.data?.photoURL} />
        ) : (
          <CircularProgress size={36} color='inherit' />
        )}
      </IconButton>
      <Menu
        open={open?.profile}
        anchorEl={anchorEl?.current}
        onClose={handleOpen('profile', false)}
      >
        {user?.data ? <MCProfileSigned /> : <MCProfileNotSigned />}
      </Menu>
    </React.Fragment>
  )
}
