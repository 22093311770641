import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  TextField
} from '@mui/material'
import React, { cloneElement, forwardRef, useEffect, useState } from 'react'
import { KuiButton } from '../Theme'
import pt from 'prop-types'

// export const DialogPrompt = forwardRef(
//   ({ value, onConfirm, ...props }, ref) => {
//     const [open, setOpen] = useState(false)
//     const [v, setV] = useState('')
//     const isComplete = Boolean(v && v !== value)

//     const handleOpen = (o) => () => setOpen(o)
//     const handleChange = ({ target }) => setV(target.value)
//     const handleConfirm = () => {
//       if (onConfirm) {
//         onConfirm(v)
//         setOpen(false)
//         if (props.clearAfterConfirm) {
//           setV('')
//         }
//       }
//     }

//     useEffect(() => {
//       setV(value)
//     }, [value])

//     return (
//       <Fragment>
//         {props.children &&
//           cloneElement(props.children, {
//             onClick: handleOpen(true)
//           })}
//         <Dialog
//           fullWidth
//           maxWidth='xs'
//           open={open}
//           onClose={handleOpen(false)}
//           TransitionComponent={Transition}
//           disableRestoreFocus
//           disableEnforceFocus
//         >
//           <DialogTitle>{props.title || 'Prompt'}</DialogTitle>
//           <DialogContent>
//             <TextField
//               fullWidth
//               variant='outlined'
//               label={props.label || 'Input'}
//               value={v || ''}
//               onChange={handleChange}
//               ref={ref}
//               autoFocus
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button
//               color='primary'
//               disabled={!isComplete}
//               onClick={handleConfirm}
//             >
//               Confirm
//             </Button>
//             <KuiButton tx='close' onClick={handleOpen(false)} />
//           </DialogActions>
//         </Dialog>
//       </Fragment>
//     )
//   }
// )

export const DialogPrompt = React.forwardRef(
  (
    {
      children,
      defaultOpen,
      title,
      label,
      onConfirm,
      value: defaultValue,
      clearAfterConfirm,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(defaultOpen || false)
    const [state, setState] = useState({
      fetching: false,
      value: defaultValue || ''
    })

    const handleOpen = (o) => () => !state?.fetching && setOpen(o)
    const handleChange = (e) => {
      setState((s) => ({ ...s, value: e?.target?.value }))
      e.persist()
    }
    const handleConfirm = async () => {
      if (state?.value && defaultValue !== state?.value) {
        setState((s) => ({ ...s, fetching: true }))
        const result = await onConfirm?.(state?.value)
        setState((s) => ({ ...s, fetching: false }))
        if (result) {
          setOpen(false)
          if (clearAfterConfirm) {
            setState((s) => ({ ...s, value: '' }))
          }
        }
      }
    }

    return (
      <React.Fragment>
        {children &&
          cloneElement(children, {
            onClick: handleOpen(true)
          })}
        <Dialog
          fullWidth
          maxWidth='xs'
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Grow}
          disableRestoreFocus
          disableEnforceFocus
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <TextField
              autoFocus
              inputRef={ref}
              placeholder={label}
              value={state?.value}
              onChange={handleChange}
              disabled={state?.fetching}
            />
          </DialogContent>
          <DialogActions>
            <KuiButton
              loading={Boolean(state?.fetching)}
              tx='confirm'
              onClick={handleConfirm}
              disabled={!state?.value || defaultValue === state?.value}
            />
            <KuiButton
              tx='close'
              onClick={handleOpen(false)}
              disabled={state?.fetching}
            />
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
)
DialogPrompt.propTypes = {
  children: pt.node,
  defaultOpen: pt.bool,
  defaultValue: pt.string,
  title: pt.node.isRequired,
  label: pt.string.isRequired,
  onConfirm: pt.func.isRequired,
  clearAfterConfirm: pt.bool
}
