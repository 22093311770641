import { faUserLock } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { Container } from '../../Container'

const StyleBox = styled(Box)(({ theme }) => ({
  '--fa-primary-color': theme?.palette?.primary?.main
}))

export const MCRestrict = (props) => {
  return (
    <Container maxWidth='xs'>
      <StyleBox textAlign='center'>
        <FontAwesomeIcon icon={faUserLock} size='10x' />
        <Box sx={{ mb: 4 }} />
        <Typography variant='h6'>Restrict Area</Typography>
      </StyleBox>
    </Container>
  )
}
