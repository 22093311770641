import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material'
import React from 'react'
import propsTypes from 'prop-types'
import { KuiButton } from '../Theme'

export const DialogRemove = ({ open, onClose, onConfirm, ...props }) => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      <DialogTitle>{props.title || 'Remove'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.label || 'Do you want to remove?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          children='Remove'
          color='error'
          {...props.confirmButtonProps}
          onClick={onConfirm}
        />
      </DialogActions>
    </Dialog>
  )
}
DialogRemove.propTypes = {
  open: propsTypes.bool.isRequired,
  title: propsTypes.node,
  label: propsTypes.node,
  onClose: propsTypes.func.isRequired,
  onConfirm: propsTypes.func.isRequired,
  confirmButtonProps: propsTypes.shape(Button.propTypes)
}
