import { Alert, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useMain } from '../KuiProvider'

export const AlertItem = ({ item }) => {
  const store = useMain()
  const [, setAlerts] = store.alerts

  const handleClose = () =>
    setAlerts((a) => [...a.filter((i) => i.key !== item.key)])

  useEffect(() => {
    setTimeout(() => {
      setAlerts((a) => [...a.filter((i) => i.key !== item.key)])
    }, 5000)
  }, [item])

  return (
    <Alert
      variant='filled'
      severity={item.severity || 'success'}
      onClose={handleClose}
    >
      {item.label || 'No label'}
    </Alert>
  )
}

export const Alerts = withStyles((theme) => ({
  rootAlert: {
    position: 'fixed',
    padding: theme.spacing(1),
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: 272,
    zIndex: theme?.zIndex?.modal + 1,
  }
}))(({ classes, ...props }) => {
  const store = useMain()
  const [alerts] = store.alerts

  return (
    <div className={classes.rootAlert}>
      <Grid container spacing={1}>
        {alerts.map((item) => (
          <Grid xs={12} item key={item.key}>
            <AlertItem item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
})
