import { faSignIn } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  TextField,
  alpha,
  Avatar
} from '@mui/material'
import { styled } from '@mui/styles'
import React, { useState } from 'react'
import { useMC } from '../ctx'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { ForgetPassword } from './forget.password'
import { Box } from '@mui/system'
import { GoogleSignIn } from './google.sign.in'

const EnhanceDialog = styled(Dialog)(({ theme }) => ({
  backgroundColor: alpha(theme?.palette?.primary?.main, 0.5),
  backdropFilter: 'blur(4px)'
}))
const SiteLogo = styled(Avatar)(({ theme }) => ({
  width: '128px !important',
  height: '128px !important',
  margin: '0 auto',
  marginBottom: theme?.spacing(4)
}))

export const MCAuth = (props) => {
  const { firebase, open, signInOnly, handleOpen, user, logo } = useMC()
  const [err, setErr] = useState('')
  const [data, setData] = useState({ email: '', pass: '' })

  const handleChange =
    (key) =>
    ({ target: { value } }) =>
      setData((d) => ({ ...d, [key]: value }))
  const handleSignIn = async () => {
    if (data?.email && data?.pass) {
      await signInWithEmailAndPassword(firebase?.auth, data?.email, data?.pass)
    }
  }

  return (
    <EnhanceDialog
      maxWidth='xs'
      open={Boolean(user?.fetched && !user?.data && (open?.auth || signInOnly))}
      onClose={handleOpen('auth', false)}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
    >
      <DialogContent>
        <SiteLogo src={logo} />
        <TextField
          label='E-mail'
          name='email'
          value={data?.email}
          onChange={handleChange('email')}
        />
        <TextField
          label='Password'
          name='password'
          type='password'
          value={data?.pass}
          onChange={handleChange('pass')}
        />
        <Button
          fullWidth
          variant='contained'
          size='large'
          startIcon={<FontAwesomeIcon icon={faSignIn} />}
          disabled={!Boolean(data?.email && data?.pass)}
          onClick={handleSignIn}
        >
          Sign In
        </Button>
        <GoogleSignIn sx={{mt:4}} />
        <Box textAlign='center' sx={{mt:4}}>
          <ForgetPassword />
        </Box>
      </DialogContent>
    </EnhanceDialog>
  )
}
