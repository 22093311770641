import { faSignIn } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useMC } from '../ctx'

export const MCProfileNotSigned = (props) => {
  const { handleOpen } = useMC()

  const handleSignIn = () => {
    handleOpen('profile', false)()
    handleOpen('auth', true)()
  }

  return (
    <List>
      <ListItem button onClick={handleSignIn}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faSignIn} />
        </ListItemIcon>
        <ListItemText primary='Sign In' />
      </ListItem>
    </List>
  )
}
