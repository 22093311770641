import {
  faCopy,
  faEdit,
  faInfoCircle,
  faTrash
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import React from 'react'
import pt from 'prop-types'

const FAIcon = (props) => <FontAwesomeIcon size='xs' {...props} />

export const KuiActionIcon = ({ tx, ...props }) => {
  let newProps = {}

  switch (tx) {
    case 'copy':
      newProps = {
        children: <FAIcon icon={faCopy} />,
        ...props
      }
      break
    case 'edit':
      newProps = {
        children: <FAIcon icon={faEdit} />,
        color: 'primary',
        ...props
      }
      break
    case 'info':
      newProps = {
        children: <FAIcon icon={faInfoCircle} />,
        ...props
      }
      break
    case 'remove':
      newProps = {
        children: <FAIcon icon={faTrash} />,
        color: 'error',
        ...props
      }
      break
    default:
      newProps = {
        ...props
      }
      break
  }

  return <IconButton size='small' {...newProps} />
}
KuiActionIcon.propTypes = {
  tx: pt.oneOf(['copy', 'edit', 'info', 'remove'])
}
