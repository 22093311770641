import { Drawer, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useMC } from '../ctx'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme?.shape?.sidebarWidth,
    zIndex: `${theme?.zIndex?.appBar - 1} !important`
  },
  modal: {
    zIndex: `${theme?.zIndex?.appBar - 1} !important`
  }
}))

export const MCSidebar = (props) => {
  const cs = useStyles()
  const { sidebar, isMobile, open, handleOpen } = useMC()
  return Boolean(sidebar) ? (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={open.sidebar}
      onClose={handleOpen('sidebar', false)}
      ModalProps={{ className: cs.modal }}
      PaperProps={{ className: cs.paper }}
    >
      <Toolbar />
      {sidebar}
      <Toolbar />
    </Drawer>
  ) : null
}
