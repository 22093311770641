import { faSignOut } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { useMC } from '../ctx'
import { signOut } from 'firebase/auth'

export const MCProfileSigned = (props) => {
  const { firebase, profileMenu } = useMC()

  const handleSignOut = () => signOut(firebase?.auth)

  return (
    <List>
      {profileMenu}
      <ListItem dense button onClick={handleSignOut}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faSignOut} />
        </ListItemIcon>
        <ListItemText primary='Sign Out' />
      </ListItem>
    </List>
  )
}
