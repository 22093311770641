import React from 'react'
import { DataGrid as DG } from '@mui/x-data-grid'
import { styled, Typography } from '@mui/material'
import { faFileTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NoRow = styled((props) => (
  <div {...props}>
    <FontAwesomeIcon size='4x' icon={faFileTimes} />
    <Typography color='textSecondary' sx={{ mt: 2 }}>
      No Row
    </Typography>
  </div>
))(({ theme }) => Object.assign({}, {
  width: '100%',
  paddingTop: theme?.spacing(12),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}, theme?.palette?.duotone))

export const DataGrid = (props) => {
  return (
    <DG
      autoHeight
      pageSize={10}
      rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]}
      components={{
        NoRowsOverlay: NoRow
      }}
      {...props}
    />
  )
}
DataGrid.propTypes = DG.propTypes
DataGrid.type = DG.type
