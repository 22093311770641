import React from 'react'
import { MCAppBar } from './appbar'
import { MCContent } from './content'
import pt from 'prop-types'
import { connectMC, useMC } from './ctx'
import { MCSidebar } from './sidebar'
import { MCAuth } from './auth'
import { Alerts } from '../Alerts'
import { MCRestrict } from './restrict'
import { Backdrop, CircularProgress } from '@mui/material'

export const MainContainer = connectMC(({ children }) => {
  const { signInOnly, user, restrict, loading } = useMC()

  let content = null

  if (loading) {
    content = (
      <MCContent>
        <Backdrop open={true} sx={{ color: 'white' }}>
          <CircularProgress size={64} color='inherit' />
        </Backdrop>
      </MCContent>
    )
  } else if (restrict) {
    content = (
      <MCContent>
        <MCRestrict />
      </MCContent>
    )
  } else if (!Boolean(signInOnly && !user?.data)) {
    content = (
      <React.Fragment>
        <MCContent>{children}</MCContent>
        <MCSidebar />
      </React.Fragment>
    )
  }

  return (
    <div>
      {content}
      <MCAppBar />
      <MCAuth />
      <Alerts />
    </div>
  )
})
MainContainer.propTypes = {
  children: pt.node.isRequired,
  sidebar: pt.node,
  dense: pt.bool,
  profileMenu: pt.node,
  restrict: pt.bool,
  appBarStart: pt.node,
  loading: pt.bool
}
