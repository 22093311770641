import React, { createContext, useContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { ThemeProvider as OGThemeProvider } from '@material-ui/core'
import { StylesProvider, createGenerateClassName } from '@mui/styles'
import { deepmerge } from '@mui/utils'
import { defaultTheme } from './default.theme'
import './style.css'
import pt from 'prop-types'
import { BrowserRouter } from 'react-router-dom'

const generateClassName = createGenerateClassName({
  seed: 'docs'
})

export const MainContext = createContext({})

const genKey = () => Math.floor(Math.random() * 1000000)

const MainProvider = ({ children, firebaseConfig, ...props }) => {
  const [user, setUser] = useState({
    fetched: false,
    data: null,
    claims: null
  })
  const [alerts, setAlerts] = useState([])
  const [FB, setFB] = useState({ app: null, db: null, auth: null })

  const store = {
    ...props,
    firebase: FB,
    user,
    alerts: [alerts, setAlerts],
    addAlert: ({ label, severity }) => {
      if (label || severity) {
        setAlerts((a) => [...a, { label, severity, key: genKey() }])
      }
    },
    isMobile: useMediaQuery(useTheme()?.breakpoints?.down('sm'))
  }

  useEffect(() => {
    if (firebaseConfig) {
      const app = initializeApp(firebaseConfig)
      const auth = getAuth(app)
      const db = getFirestore(app)
      setFB({ app, auth, db })
    }
  }, [firebaseConfig])

  useEffect(() => {
    if (FB.auth) {
      return onAuthStateChanged(FB.auth, async (user) => {
        if (user) {
          const { claims } = await user?.getIdTokenResult()
          setUser((u) => ({
            ...u,
            fetched: true,
            data: user,
            claims,
          }))
        } else {
          setUser((u) => ({ ...u, fetched: true, data: null, claims: null }))
        }
      })
    }
  }, [FB.auth])

  return <MainContext.Provider value={store}>{children}</MainContext.Provider>
}

export const KuiProvider = ({
  children,
  theme: customTheme,
  basename,
  ...props
}) => {
  const mergeTheme = createTheme(deepmerge(defaultTheme, customTheme))
  return (
    <StylesProvider generateClassName={generateClassName}>
      <OGThemeProvider theme={mergeTheme}>
        <ThemeProvider theme={mergeTheme}>
          <BrowserRouter basename={basename}>
            <MainProvider {...props}>{children}</MainProvider>
          </BrowserRouter>
        </ThemeProvider>
      </OGThemeProvider>
    </StylesProvider>
  )
}
KuiProvider.propTypes = {
  children: pt.node.isRequired,
  theme: pt.object,
  firebaseConfig: pt.shape({
    apiKey: pt.string.isRequired,
    authDomain: pt.string.isRequired,
    projectId: pt.string.isRequired,
    appId: pt.string.isRequired
  }).isRequired,
  logo: pt.string.isRequired,
  sitename: pt.node,
  profileMenu: pt.node,
  appBarStart: pt.node
}

export const useMain = () => useContext(MainContext)
