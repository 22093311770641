import { Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { ReactComponent as Icon } from './g.logo.svg'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { useMain } from '../../KuiProvider'

const EnhanceButton = styled((props) => (
  <Button {...props} fullWidth size='large' />
))(({ theme }) => ({
  border: `solid 1px ${theme?.palette?.grey[300]}`,
  color: theme?.palette?.text?.primary,
  textTransform: 'none'
}))

export const GoogleSignIn = ({ sx, ...props }) => {
  const { firebase, addAlert } = useMain()

  const handleOpen = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(firebase?.auth, provider).catch((err) =>
      addAlert({ label: err.message, severity: 'error' })
    )
  }

  return (
    <Box sx={sx}>
      <EnhanceButton startIcon={<Icon />} onClick={handleOpen}>
        Sign in with Google
      </EnhanceButton>
    </Box>
  )
}
GoogleSignIn.propTypes = Box.propTypes
