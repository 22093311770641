import React, { createContext, useContext, useState } from 'react'
import { useMain } from '../KuiProvider'

const MCContext = createContext({})

const MCProvider = ({ children, ...props }) => {
  const [open, setOpen] = useState({
    sidebar: true,
    profile: false,
    auth: false
  })
  const store = {
    ...props,
    open,
    handleOpen: (key, value) => () => setOpen((o) => ({ ...o, [key]: value })),
    ...useMain(),
  }
  return <MCContext.Provider value={store}>{children}</MCContext.Provider>
}
export const connectMC = (Comp) => (props) =>
  (
    <MCProvider {...props}>
      <Comp {...props} />
    </MCProvider>
  )

export const useMC = () => {
  const store = useContext(MCContext)
  return store
}
