import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Blurhash } from 'react-blurhash'

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  blurhash: {
    width: '100% !important',
    height: '100% !important',
    backgroundColor: 'blue'
  },
  fluid: theme.shape.fluid,
  square: {
    width: '100%',
    position: 'relative',
    paddingTop: '100%',
    '&>div': theme.shape.fluid
  },
  contain: {
    width: '100%',
    textAlign: 'center',
    "&>img": {
      maxWidth: '100%',
    }
  }
}))

export const DisplayImage = ({ variant, thumbnail, data }) => {
  const cs = useStyles()
  const [isload, setIsload] = useState(false)
  const imageURL = data?.content?.[thumbnail ? 'thumbnail' : 'original']

  useEffect(() => {
    const image = new Image()
    image.src = imageURL
    image.onload = () => setIsload(true)
  }, [imageURL])

  if (variant === 'fluid') {
    return isload ? (
      <div
        className={clsx(cs.fluid, cs.bg)}
        style={{ backgroundImage: `url(${imageURL})` }}
      />
    ) : (
      <div className={clsx(cs.fluid)}>
        <Blurhash
          className={cs.blurhash}
          hash={data?.blurhash || 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
    )
  } else if (variant === 'normal') {
    return isload ? <div className={cs.contain}>
      <img src={imageURL} />
    </div> : (
      <div className={clsx(cs.square)}>
        <div>
          <Blurhash
            className={cs.blurhash}
            hash={data?.blurhash || 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </div>
      </div>
    )
  }

  return <div className={cs.root}>image</div>
}
DisplayImage.defaultProps = {
  variant: 'fluid',
  thumbnail: false
}
