import { Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useMC } from '../ctx'
import clsx from 'clsx'
import { Box } from '@mui/system'

const useStyles = makeStyles((theme) => ({
  sidebarPad: {
    paddingLeft: theme?.shape?.sidebarWidth + 2,
    color: theme?.palette?.text?.secondary
  }
}))

export const MCContent = ({ children }) => {
  const cs = useStyles()
  const { sidebar, dense, isMobile } = useMC()

  return (
    <div
      className={clsx({
        [cs.sidebarPad]: Boolean(sidebar && !isMobile)
      })}
    >
      <Toolbar />
      <Box sx={{ pt: dense ? 0 : 6 }}>{children}</Box>
      <Toolbar />
    </div>
  )
}
