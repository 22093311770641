import React from 'react'
import pt from 'prop-types'
import { ListItemText } from '@mui/material'
import { deepmerge } from '@mui/utils'

export const KuiListItemText = ({ tx, ...props }) => {
  const { primaryTypographyProps, secondaryTypographyProps, ...others } = props
  switch (tx) {
    case 'sidebar':
      return (
        <ListItemText
          {...others}
          primaryTypographyProps={deepmerge(
            { variant: 'body2' },
            primaryTypographyProps
          )}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      )
    default:
      return (
        <ListItemText
          {...others}
          primaryTypographyProps={deepmerge(
            { color: 'textSecondary' },
            primaryTypographyProps
          )}
          secondaryTypographyProps={secondaryTypographyProps}
        />
      )
  }
}
KuiListItemText.propTypes = {
  tx: pt.oneOf(['sidebar', 'body']).isRequired
}
