import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import propTypes from 'prop-types'
import { Link as RLink } from 'react-router-dom'

export const ContentHeader = withStyles((theme) => ({
  rootContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: ({ bottom }) => theme.spacing(bottom || 6)
  },
  breadcrumbs: {
    fontSize: theme.typography.caption.fontSize
  },
  content: {
    flex: 1
  },
  actions: {}
}))(({ classes, label, breadcrumbs, actions, secondary, ...props }) => {
  return (
    <div className={classes.rootContentHeader}>
      <div className={classes.content}>
        {Boolean(breadcrumbs && breadcrumbs.length) && (
          <div style={{ marginBottom: '0.5em' }}>
            <Breadcrumbs className={classes.breadcrumbs} separator='|'>
              {breadcrumbs.map((item, index) => {
                if (item.to) {
                  return (
                    <Link component={RLink} to={item.to} key={index}>
                      <Typography
                        variant='caption'
                        color='inherit'
                        style={{ fontWeight: 'bold' }}
                      >
                        {item.label}
                      </Typography>
                    </Link>
                  )
                } else {
                  return (
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      key={index}
                    >
                      {item.label}
                    </Typography>
                  )
                }
              })}
            </Breadcrumbs>
          </div>
        )}
        <Typography
          variant='h3'
          color={label ? 'textPrimary' : 'textSecondary'}
        >
          {label || 'No title'}
        </Typography>
        {secondary && (
          <Typography
            variant='caption'
            color='textSecondary'
            component='div'
            style={{ paddingTop: 8 }}
          >
            {secondary}
          </Typography>
        )}
      </div>
      {Boolean(actions) && <div className={classes.actions}>{actions}</div>}
    </div>
  )
})
ContentHeader.propTypes = {
  label: propTypes.node.isRequired,
  actions: propTypes.node,
  secondary: propTypes.node,
  breadcrumbs: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.node.isRequired,
      to: propTypes.string
    })
  )
}
