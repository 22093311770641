import React from 'react'
import pt from 'prop-types'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFolderOpen,
  faPlus,
  faSave,
  faSignOut,
  faSpinner,
  faTrash
} from '@fortawesome/pro-duotone-svg-icons'

const FAicon = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone
  }
}))(({ classes: cs, ...props }) => (
  <FontAwesomeIcon className={cs.root} {...props} />
))

export const KuiButton = ({ children, tx, loading, ...props }) => {
  const t = (text) => text

  let btnProps

  switch (tx) {
    case 'add':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faPlus} />,
        children: children || 'Add',
        ...props
      }
      break
    case 'bin':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faTrash} />,
        children: children || 'Trash',
        ...props
      }
      break
    case 'browse':
      btnProps = {
        variant: 'outlined',
        startIcon: <FAicon icon={faFolderOpen} />,
        component: 'span',
        children: children || 'Browse',
        ...props
      }
      break
    case 'cancel':
      btnProps = {
        children: children || 'Cancel',
        ...props
      }
      break
    case 'clear':
      btnProps = {
        color: 'secondary',
        children: children || 'Clear',
        ...props
      }
      break
    case 'confirm':
      btnProps = {
        color: 'primary',
        children: children || 'Confirm',
        ...props
      }
      break
    case 'close':
      btnProps = {
        children: children || 'Close',
        color: 'inherit',
        ...props
      }
      break
    case 'loading':
      btnProps = {
        children: children || 'Please wait',
        color: 'inherit',
        startIcon: <FontAwesomeIcon icon={faSpinner} pulse />,
        disabled: true,
        ...props
      }
      break
    case 'remove':
      btnProps = {
        startIcon: <FontAwesomeIcon icon={faTrash} />,
        color: 'error',
        children: children || 'Remove',
        ...props
      }
      break
    case 'save':
      btnProps = {
        variant: 'contained',
        startIcon: <FontAwesomeIcon icon={faSave} />,
        color: 'primary',
        children: children || 'Save',
        ...props
      }
      break
    case 'signout':
      btnProps = {
        startIcon: <FontAwesomeIcon icon={faSignOut} />,
        color: 'secondary',
        children: children || 'Sign Out',
        ...props
      }
      break
    default:
      btnProps = {
        ...props
      }
      break
  }

  if (loading) {
    btnProps = {
      ...btnProps,
      startIcon: <FontAwesomeIcon icon={faSpinner} pulse />,
      disabled: true,
      children: 'Please wait'
    }
  }

  return <Button {...btnProps} />
}
KuiButton.propTypes = {
  ...Button.propTypes,
  tx: pt.oneOf([
    'add',
    'bin',
    'browse',
    'cancel',
    'clear',
    'confirm',
    'close',
    'loading',
    'remove',
    'save',
    'signout'
  ]).isRequired,
  children: pt.node,
  loading: pt.bool
}
