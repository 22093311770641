import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  Box
} from '@mui/material'
import React, { cloneElement, forwardRef, Fragment, useState } from 'react'
import pt from 'prop-types'
import { KuiButton } from '../Theme'

const Transition = forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
))

export const DialogPre = forwardRef(
  ({ defaultOpen, loading, ...props }, ref) => {
    const [open, setOpen] = useState(defaultOpen || false)

    const handleOpen = (o) => () => !loading && setOpen(o)
    const handleConfirm = async () => {
      if (props.onConfirm) {
        const result = await props.onConfirm()
        if (result) {
          setOpen(false)
        }
      }
    }

    return (
      <Fragment>
        {props.button &&
          cloneElement(props.button, {
            onClick: handleOpen(!open)
          })}
        <Dialog
          ref={ref}
          fullWidth
          maxWidth={props.maxWidth || 'xs'}
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Transition}
          disableEnforceFocus
        >
          <DialogTitle>{props.title || 'Title'}</DialogTitle>
          <DialogContent>{props.children}</DialogContent>
          <DialogActions>
            {
              loading
                ? <KuiButton tx="loading" />
                : <React.Fragment>
                              {props.secondaryActions}
            <Box flex={1} />
            {props.onConfirm && (
              <Button
                color='primary'
                children={'Confirm'}
                {...props.confirmButtonProps}
                onClick={handleConfirm}
              />
            )}
            <KuiButton tx='close' onClick={handleOpen(false)} />
                  </React.Fragment>
            }
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
)
DialogPre.propTypes = {
  button: pt.node.isRequired,
  onConfirm: pt.func,
  maxWidth: pt.oneOf(["xs","sm","md","lg","xl"]),
  title: pt.node,
  children: pt.node,
  confirmButtonProps: pt.shape(Button.propTypes),
  defaultOpen: pt.bool,
  loading: pt.bool
}
