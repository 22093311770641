import { Container as OContainer } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import propTypes from 'prop-types'
import { Box } from '@mui/system'

export const Container = styled(props => <Box sx={{px:3}}>
  <OContainer {...props} />
</Box>)(()=>({
  padding: '0 !important'
}))
Container.propTypes = {
  maxWidth: propTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}
